import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { useTheme } from '../../styles/useTheme';


export type TextFieldProps = MuiTextFieldProps & {
  style?: React.CSSProperties;
};

/**
 * A replacement for the Material UI TextField component, prestyled for our application.
 *
 * 
 */
export const TextField: React.FC<TextFieldProps> = ({
  style,
  ...props
}) => {
  const [theme] = useTheme();

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: theme.type,
          background: {
            default: theme.backgroundColor,
            paper: theme.backgroundColor,
          },
        },
      }),
    [theme]
  );

  return (
    <ThemeProvider theme={muiTheme}>
        <MuiTextField
            {...props}
            variant="outlined"
            style={{
            backgroundColor: theme.backgroundColor,
            borderRadius: '0.4em',
            width: '100%',
            ...style,
            }}
        />
    </ThemeProvider>
  );
};
