import * as React from 'react';
import {
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
} from 'reactstrap';

import styled from 'styled-components';
import { showKeyModal } from '../../operations/showKeyModal';
import { OktaAuth } from '@okta/okta-auth-js';
import * as Model from '../../models';
import { useDispatch } from 'react-redux';
import { InfoIcon } from '@primer/octicons-react';
import { useTheme } from '../../styles/useTheme';
import { TextField } from '../TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';

interface Component {
  name: string;
  number: string;
}

export interface DispatchProps {
  getKeys: (
    auth: OktaAuth,
    account: Model.Account,
    role: Model.Role,
    duration: number
  ) => void;
}

const ChangeModal = ({ closeModal, account, role, duration, auth, url }) => {
  const [changeNumber, setChangeNumber] = React.useState('');
  const [primaryCINumber, setPrimaryCINumber] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Component[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [theme] = useTheme();

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
    (async () => {
      setLoading(true);
      try {
        const response = await fetch('https://dev.alks.coxautoinc.com/components.json', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
          const data = await response.json();
          console.log('Fetched components:', data.length);
          setOptions(data.map((component) => ({
            name: component.name,
            number: component.number,
          })));
        } else {
          console.error('Invalid response or content type is not JSON');
        }
      } catch (error) {
        console.error('Error fetching components:', error);
      }
      setLoading(false);
    })();
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const handleSubmit = React.useCallback(async () => {
    // if a url is provided to the change modal, then we know we are coming from the "Console" button
    if (url) {
      if (changeNumber) {
        window.open(`${url}&changeNumber=${changeNumber}`);
      }
      if (primaryCINumber) {
        window.open(`${url}&primaryCINumber=${primaryCINumber}&description="Console Access"`);
      }
    } else {
      // if no url is provided, then we are coming from the "Get Keys" button/dropdown, so we need to show the Key Modal
      dispatch(
        showKeyModal(
          auth,
          account,
          role,
          duration,
          changeNumber,
          primaryCINumber,
          description
        )
      );
    }
  }, [
    url,
    changeNumber,
    primaryCINumber,
    dispatch,
    auth,
    account,
    role,
    duration,
    description,
  ]);

  const isSubmitDisabled: boolean =
    (!changeNumber && !primaryCINumber) ||
    (!!changeNumber && !!primaryCINumber);

  return (
    <div>
      <StyledModalHeader toggle={closeModal}>
        AWS Account Access
      </StyledModalHeader>
      <StyledModalBody>
        <div style={{ marginBottom: '1rem' }}>
          <Label for="requestedDuration" style={{ fontWeight: 'bold' }}>
            Requested Key Duration:
          </Label>
          <div style={{ padding: '0.5rem', borderRadius: '0.25rem' }}>
            {duration} hour(s)
          </div>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Label for="requestedRole" style={{ fontWeight: 'bold' }}>
            Requested Role:
          </Label>
          <div style={{ padding: '0.5rem', borderRadius: '0.25rem' }}>
            {role.name}
          </div>
        </div>
        <StyledAlert color="warning">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon size={24} />
            <div style={{ marginLeft: '1rem' }}>
              For the selected role, please provide either an existing
              ServiceNow Change Ticket # or a Workload Component ID for ALKS to
              create a change ticket on your behalf.
            </div>
          </div>
        </StyledAlert>
        <Label style={{ fontWeight: 'bold' }} for="changeNumber">
          Existing Change Request
        </Label>
        <TextField
          id="changeNumber"
          label="CHGXXXXXXX"
          variant="outlined"
          value={changeNumber}
          sx={{ backgroundColor: theme.backgroundColor, color: theme.textColor }}
          onChange={(e) => setChangeNumber(e.target.value)}
          disabled={!!primaryCINumber}
          fullWidth
          multiline={false}
        />
        <div style={{ textAlign: 'center', margin: '1rem 0' }}>
          <span>OR</span>
        </div>
        <Label style={{ fontWeight: 'bold' }} for="workloadComponentId">
          Workload Component ID
        </Label>
        <Autocomplete
          id="workloadComponentId"
          open={open}
          disabled={!!changeNumber}
          slotProps={{
            paper: {
              sx: {
          backgroundColor: theme.backgroundColor,
          color: theme.textColor,
          border: `1px solid ${theme.borderColor}`,
              },
            },
          }}
          options={options}
          loading={loading}
          onOpen={handleOpen}
          onClose={handleClose}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type to Search...CIXXXXX or Workload Name"
              variant="outlined"
              value=""
              multiline={false}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, value) => {
            if (value) {
              const number = value.number;
              setPrimaryCINumber(number);
            } else {
              setPrimaryCINumber("");
            }
          }}
        />
        <div style={{ marginBottom: '1rem' }}></div>
        <Label style={{ fontWeight: 'bold' }} for="shortDescription">
          Short Description
        </Label>
        <TextField
          id="shortDescription"
          label="Details of the change"
          variant="outlined"
          value={description}
          multiline
          rows={2}
          onChange={(e) => setDescription(e.target.value)}
          disabled={!!changeNumber}
          fullWidth
        />
      </StyledModalBody>
      <StyledModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          Submit
        </Button>
        <Button color="secondary" onClick={closeModal}>
          Cancel
        </Button>
      </StyledModalFooter>
    </div>
  );
};

const StyledModalBody = styled(ModalBody)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledModalFooter = styled(ModalFooter)`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};
`;

const StyledAlert = styled(Alert)`
  color: ${({ theme }) => (theme.type === 'dark' ? 'secondary' : 'primary')};
  border: 1px solid ${({ theme }) => theme.borderColor};
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: ${(props) => props.theme.modal.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  border-color: ${({ theme }) => theme.borderColor};

  && .close {
    color: ${({ theme }) => theme.textColor};
    text-shadow: 0 1px 0 ${({ theme }) => theme.modal.backgroundColor};
  }
`;

export default ChangeModal;
